import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "fontsource-nunito";

import App from './App';
import reportWebVitals from './reportWebVitals';
import Spinner from "./Components/Spinner/Spinner";

export const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: '46px',
            fontWeight: 800,
            // '@media (min-width:600px)': {
            //     fontSize: '30px',
            // },
        },
        h2: {
            fontSize: '24px',
            fontWeight: 800,
            marginBottom: '15px'
        },
        h3: {
            fontSize: '50px',
            fontWeight: 800,
        },
        h4: {
            fontSize: '40px',
            fontWeight: 800,
        },
        h5: {
            fontSize: '28px',
        },
    },
    palette: {
        background: {
            default: "#F5F7FE"
        },
        common: {
            black: '#000000',
            white: '#ffffff',
        },
        primary: {
            main: '#0FADE4',
        },
        secondary: {
            main: '#C60FAA',
        },
        error: {
            main: '#ff1744',
        },
        text: {
            primary: '#171841',
            secondary: '#43444B',
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Suspense fallback={<Spinner isFixed />}>
            <App/>
        </Suspense>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
