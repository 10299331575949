import React from 'react';

import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {Home} from "./Pages/Home";
import {PrivacyPolicy} from "./Pages/PrivacyPolicy";

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>

                <Route path="/privacy-policy">
                    <PrivacyPolicy/>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
