import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    spinner: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
    isFixed: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));
