import React, { memo } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import clsx from 'clsx';

import useStyles from './Spinner.styles';

function Spinner({isFixed}) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.spinner, isFixed && classes.isFixed)}>
            <CircularProgress/>
        </div>
    );
}

Spinner.defaultProps = {
    isFixed: false,
};

export default memo(Spinner);
